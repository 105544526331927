<template>
	<IonApp>
		<IonContent v-if='showWelcome' id='welcome'>
			<ion-router-outlet id='main-content'></ion-router-outlet>
		</IonContent>
		<IonSplitPane v-else content-id='main-content'>
			<ion-menu content-id='main-content' type='overlay'>
				<ion-content>
					<IonImg src='/assets/images/logo.png' class='pt-4' style='height: 80px;'></IonImg>
					<ion-list id='class-list'>
						<ion-list-header>Booking</ion-list-header>
						<ion-menu-toggle auto-hide='false'>
							<ion-item @click='selectedIndex = i' router-direction='root' router-link='/classes' lines='none' detail='false' class='hydrated' :class='{ selected: routeName === "classes" }'>
								<IonIcon src='/assets/icon/calendar-outline.svg'></IonIcon>
								<IonLabel>Classes</IonLabel>
							</ion-item>
<!--							<ion-item @click='selectedIndex = 2' router-direction='root' router-link='/fit3d-scanner' lines='none' detail='false' class='hydrated' :class='{ selected: routeName === "fit3d-scanner" }'>-->
<!--								<IonIcon src='/assets/icon/calendar-outline.svg'></IonIcon>-->
<!--								<IonLabel>Fit3D Scanner</IonLabel>-->
<!--							</ion-item>-->
						</ion-menu-toggle>
					</ion-list>

				</ion-content>
			</ion-menu>
			<ion-router-outlet id='main-content'></ion-router-outlet>
		</IonSplitPane>
	</IonApp>
</template>

<script>
import {IonApp, IonButton, IonCol, IonContent, IonGrid, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote, IonRouterOutlet, IonRow, IonSplitPane} from '@ionic/vue';
import {defineComponent, reactive, ref, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp} from 'ionicons/icons';

export default defineComponent({
	name: 'App',
	components: {
		IonApp,
		IonButton,
		IonCol,
		IonContent,
		IonGrid,
		IonIcon,
		IonInput,
		IonImg,
		IonItem,
		IonLabel,
		IonList,
		IonListHeader,
		IonMenu,
		IonMenuToggle,
		IonNote,
		IonRouterOutlet,
		IonRow,
		IonSplitPane,
	},
	setup(props, {}) {
		let refreshing = false;
		let registration;
		let updateExists = false;
		let updateAvailable = function(event) {
			registration = event.detail
			console.log(registration)
			updateExists = true

			updateExists = false
			// Make sure we only send a 'skip waiting' message if the SW is waiting
			if (!registration || !registration.waiting) return
			// send message to SW to skip the waiting and activate the new SW
			registration.waiting.postMessage({ type: 'SKIP_WAITING' })
		}

		document.addEventListener('swUpdated', updateAvailable, { once: true })

		navigator.serviceWorker.addEventListener('controllerchange', () => {
			if (refreshing) return
			refreshing = true
			// Here the actual reload of the page occurs
			window.location.reload()
		})


		const loggedIn = ref(false);
		const selectedIndex = ref(0);

		const route = reactive(useRoute());
		const router = useRouter();
		const showWelcome = ref(route.name === 'welcome');
		const routeName = ref(route.name);

		watch(() => route.name, () => {
			routeName.value = route.name;
			if(route.name === 'welcome') {
				showWelcome.value = true;
			}
			else {
				showWelcome.value = false;
			}
		});

		// showWelcome.value = computed(() => {
		// 	console.log('computed')
		// 	route.name === 'welcome';
		// });

		let login = function() {
			loggedIn.value = true;
		};

		const enterAsGuest = function() {
			router.push('classes');
		};

		return {
			routeName,
			showWelcome,
			loggedIn,
			selectedIndex,
			login,
			enterAsGuest
		};
	}
});
</script>

<style scoped>
ion-list-header {
    text-transform: uppercase;
    color: var(--ion-color-medium-shade);
    font-family: Arial;
    font-size: 1rem;
}

ion-item {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    margin-right: 1.5rem;
}

ion-icon {
    margin-left: 1rem;
    margin-right: .5rem;
}

#welcome {
    --background: url(../public/assets/images/desktop-welcome.jpg) no-repeat center center / cover;
}

#welcome:before {
    filter: blur(8px);
    -webkit-filter: blur(8px);
}

#login {
    background: #fff;
    color: #000;
    display: inline-block;
    padding: 1rem;
    border-radius: 10px;
}

ion-menu.md ion-item.selected {
    --background: rgba(var(--ion-color-secondary-rgb), 0.5);
}
</style>
